import React, { useState } from 'react'
import Input from '@cloudscape-design/components-themed/input'

// https://cloudscape.design/components/input
// This control triggers its onChange event when the enter key is pressed

export function RadInput2 ({ initialValue, onEnter, ...rest }) {
  const [value, setValue] = useState(initialValue ?? '')
  return (
    <Input
      {...rest}
      value={value}
      onChange={({ detail }) => {
        setValue(detail.value)
        if (detail.value.trim() === '') {
          setValue('')
          onEnter({ detail: { value: '' } })
        }
      }}
      onKeyDown={(x) => {
        if (x.detail.key === 'Enter') {
          onEnter({ detail: { value } })
        }
      }}
    />
  )
}
