
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18wu0_1g1na_93",
  "box": "awsui_box_18wu0_1g1na_207",
  "p-variant": "awsui_p-variant_18wu0_1g1na_207",
  "color-default": "awsui_color-default_18wu0_1g1na_207",
  "b-variant": "awsui_b-variant_18wu0_1g1na_207",
  "strong-variant": "awsui_strong-variant_18wu0_1g1na_207",
  "code-variant": "awsui_code-variant_18wu0_1g1na_207",
  "pre-variant": "awsui_pre-variant_18wu0_1g1na_207",
  "samp-variant": "awsui_samp-variant_18wu0_1g1na_207",
  "h1-variant": "awsui_h1-variant_18wu0_1g1na_211",
  "h2-variant": "awsui_h2-variant_18wu0_1g1na_211",
  "h3-variant": "awsui_h3-variant_18wu0_1g1na_211",
  "h4-variant": "awsui_h4-variant_18wu0_1g1na_211",
  "h5-variant": "awsui_h5-variant_18wu0_1g1na_211",
  "small-variant": "awsui_small-variant_18wu0_1g1na_215",
  "a-variant": "awsui_a-variant_18wu0_1g1na_219",
  "font-size-default": "awsui_font-size-default_18wu0_1g1na_223",
  "font-weight-default": "awsui_font-weight-default_18wu0_1g1na_263",
  "key-label-variant": "awsui_key-label-variant_18wu0_1g1na_303",
  "value-large-variant": "awsui_value-large-variant_18wu0_1g1na_310",
  "font-weight-heavy": "awsui_font-weight-heavy_18wu0_1g1na_317",
  "color-inverted": "awsui_color-inverted_18wu0_1g1na_322",
  "color-text-label": "awsui_color-text-label_18wu0_1g1na_325",
  "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_1g1na_328",
  "color-text-status-error": "awsui_color-text-status-error_18wu0_1g1na_331",
  "color-text-status-success": "awsui_color-text-status-success_18wu0_1g1na_334",
  "color-text-status-info": "awsui_color-text-status-info_18wu0_1g1na_337",
  "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_1g1na_340",
  "color-inherit": "awsui_color-inherit_18wu0_1g1na_343",
  "font-size-body-s": "awsui_font-size-body-s_18wu0_1g1na_346",
  "font-size-body-m": "awsui_font-size-body-m_18wu0_1g1na_351",
  "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_1g1na_355",
  "font-size-heading-s": "awsui_font-size-heading-s_18wu0_1g1na_359",
  "font-size-heading-m": "awsui_font-size-heading-m_18wu0_1g1na_364",
  "font-size-heading-l": "awsui_font-size-heading-l_18wu0_1g1na_369",
  "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_1g1na_374",
  "font-size-display-l": "awsui_font-size-display-l_18wu0_1g1na_379",
  "font-weight-light": "awsui_font-weight-light_18wu0_1g1na_384",
  "font-weight-normal": "awsui_font-weight-normal_18wu0_1g1na_387",
  "font-weight-bold": "awsui_font-weight-bold_18wu0_1g1na_390",
  "t-left": "awsui_t-left_18wu0_1g1na_397",
  "t-right": "awsui_t-right_18wu0_1g1na_401",
  "t-center": "awsui_t-center_18wu0_1g1na_405",
  "p-n": "awsui_p-n_18wu0_1g1na_506",
  "p-top-n": "awsui_p-top-n_18wu0_1g1na_510",
  "p-vertical-n": "awsui_p-vertical-n_18wu0_1g1na_511",
  "p-right-n": "awsui_p-right-n_18wu0_1g1na_515",
  "p-horizontal-n": "awsui_p-horizontal-n_18wu0_1g1na_516",
  "p-bottom-n": "awsui_p-bottom-n_18wu0_1g1na_520",
  "p-left-n": "awsui_p-left-n_18wu0_1g1na_525",
  "p-xxxs": "awsui_p-xxxs_18wu0_1g1na_530",
  "p-top-xxxs": "awsui_p-top-xxxs_18wu0_1g1na_534",
  "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_1g1na_535",
  "p-right-xxxs": "awsui_p-right-xxxs_18wu0_1g1na_539",
  "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_1g1na_540",
  "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_1g1na_544",
  "p-left-xxxs": "awsui_p-left-xxxs_18wu0_1g1na_549",
  "p-xxs": "awsui_p-xxs_18wu0_1g1na_554",
  "p-top-xxs": "awsui_p-top-xxs_18wu0_1g1na_558",
  "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_1g1na_559",
  "p-right-xxs": "awsui_p-right-xxs_18wu0_1g1na_563",
  "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_1g1na_564",
  "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_1g1na_568",
  "p-left-xxs": "awsui_p-left-xxs_18wu0_1g1na_573",
  "p-xs": "awsui_p-xs_18wu0_1g1na_578",
  "p-top-xs": "awsui_p-top-xs_18wu0_1g1na_582",
  "p-vertical-xs": "awsui_p-vertical-xs_18wu0_1g1na_583",
  "p-right-xs": "awsui_p-right-xs_18wu0_1g1na_587",
  "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_1g1na_588",
  "p-bottom-xs": "awsui_p-bottom-xs_18wu0_1g1na_592",
  "p-left-xs": "awsui_p-left-xs_18wu0_1g1na_597",
  "p-s": "awsui_p-s_18wu0_1g1na_602",
  "p-top-s": "awsui_p-top-s_18wu0_1g1na_606",
  "p-vertical-s": "awsui_p-vertical-s_18wu0_1g1na_607",
  "p-right-s": "awsui_p-right-s_18wu0_1g1na_611",
  "p-horizontal-s": "awsui_p-horizontal-s_18wu0_1g1na_612",
  "p-bottom-s": "awsui_p-bottom-s_18wu0_1g1na_616",
  "p-left-s": "awsui_p-left-s_18wu0_1g1na_621",
  "p-m": "awsui_p-m_18wu0_1g1na_626",
  "p-top-m": "awsui_p-top-m_18wu0_1g1na_630",
  "p-vertical-m": "awsui_p-vertical-m_18wu0_1g1na_631",
  "p-right-m": "awsui_p-right-m_18wu0_1g1na_635",
  "p-horizontal-m": "awsui_p-horizontal-m_18wu0_1g1na_636",
  "p-bottom-m": "awsui_p-bottom-m_18wu0_1g1na_640",
  "p-left-m": "awsui_p-left-m_18wu0_1g1na_645",
  "p-l": "awsui_p-l_18wu0_1g1na_525",
  "p-top-l": "awsui_p-top-l_18wu0_1g1na_654",
  "p-vertical-l": "awsui_p-vertical-l_18wu0_1g1na_655",
  "p-right-l": "awsui_p-right-l_18wu0_1g1na_659",
  "p-horizontal-l": "awsui_p-horizontal-l_18wu0_1g1na_660",
  "p-bottom-l": "awsui_p-bottom-l_18wu0_1g1na_664",
  "p-left-l": "awsui_p-left-l_18wu0_1g1na_669",
  "p-xl": "awsui_p-xl_18wu0_1g1na_674",
  "p-top-xl": "awsui_p-top-xl_18wu0_1g1na_678",
  "p-vertical-xl": "awsui_p-vertical-xl_18wu0_1g1na_679",
  "p-right-xl": "awsui_p-right-xl_18wu0_1g1na_683",
  "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_1g1na_684",
  "p-bottom-xl": "awsui_p-bottom-xl_18wu0_1g1na_688",
  "p-left-xl": "awsui_p-left-xl_18wu0_1g1na_693",
  "p-xxl": "awsui_p-xxl_18wu0_1g1na_698",
  "p-top-xxl": "awsui_p-top-xxl_18wu0_1g1na_702",
  "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_1g1na_703",
  "p-right-xxl": "awsui_p-right-xxl_18wu0_1g1na_707",
  "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_1g1na_708",
  "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_1g1na_712",
  "p-left-xxl": "awsui_p-left-xxl_18wu0_1g1na_717",
  "p-xxxl": "awsui_p-xxxl_18wu0_1g1na_722",
  "p-top-xxxl": "awsui_p-top-xxxl_18wu0_1g1na_726",
  "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_1g1na_727",
  "p-right-xxxl": "awsui_p-right-xxxl_18wu0_1g1na_731",
  "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_1g1na_732",
  "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_1g1na_736",
  "p-left-xxxl": "awsui_p-left-xxxl_18wu0_1g1na_741",
  "m-n": "awsui_m-n_18wu0_1g1na_746",
  "m-top-n": "awsui_m-top-n_18wu0_1g1na_750",
  "m-vertical-n": "awsui_m-vertical-n_18wu0_1g1na_751",
  "m-right-n": "awsui_m-right-n_18wu0_1g1na_755",
  "m-horizontal-n": "awsui_m-horizontal-n_18wu0_1g1na_756",
  "m-bottom-n": "awsui_m-bottom-n_18wu0_1g1na_760",
  "m-left-n": "awsui_m-left-n_18wu0_1g1na_765",
  "m-xxxs": "awsui_m-xxxs_18wu0_1g1na_770",
  "m-top-xxxs": "awsui_m-top-xxxs_18wu0_1g1na_774",
  "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_1g1na_775",
  "m-right-xxxs": "awsui_m-right-xxxs_18wu0_1g1na_779",
  "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_1g1na_780",
  "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_1g1na_784",
  "m-left-xxxs": "awsui_m-left-xxxs_18wu0_1g1na_789",
  "m-xxs": "awsui_m-xxs_18wu0_1g1na_794",
  "m-top-xxs": "awsui_m-top-xxs_18wu0_1g1na_798",
  "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_1g1na_799",
  "m-right-xxs": "awsui_m-right-xxs_18wu0_1g1na_803",
  "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_1g1na_804",
  "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_1g1na_808",
  "m-left-xxs": "awsui_m-left-xxs_18wu0_1g1na_813",
  "m-xs": "awsui_m-xs_18wu0_1g1na_818",
  "m-top-xs": "awsui_m-top-xs_18wu0_1g1na_822",
  "m-vertical-xs": "awsui_m-vertical-xs_18wu0_1g1na_823",
  "m-right-xs": "awsui_m-right-xs_18wu0_1g1na_827",
  "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_1g1na_828",
  "m-bottom-xs": "awsui_m-bottom-xs_18wu0_1g1na_832",
  "m-left-xs": "awsui_m-left-xs_18wu0_1g1na_837",
  "m-s": "awsui_m-s_18wu0_1g1na_842",
  "m-top-s": "awsui_m-top-s_18wu0_1g1na_846",
  "m-vertical-s": "awsui_m-vertical-s_18wu0_1g1na_847",
  "m-right-s": "awsui_m-right-s_18wu0_1g1na_851",
  "m-horizontal-s": "awsui_m-horizontal-s_18wu0_1g1na_852",
  "m-bottom-s": "awsui_m-bottom-s_18wu0_1g1na_856",
  "m-left-s": "awsui_m-left-s_18wu0_1g1na_861",
  "m-m": "awsui_m-m_18wu0_1g1na_866",
  "m-top-m": "awsui_m-top-m_18wu0_1g1na_870",
  "m-vertical-m": "awsui_m-vertical-m_18wu0_1g1na_871",
  "m-right-m": "awsui_m-right-m_18wu0_1g1na_875",
  "m-horizontal-m": "awsui_m-horizontal-m_18wu0_1g1na_876",
  "m-bottom-m": "awsui_m-bottom-m_18wu0_1g1na_880",
  "m-left-m": "awsui_m-left-m_18wu0_1g1na_885",
  "m-l": "awsui_m-l_18wu0_1g1na_765",
  "m-top-l": "awsui_m-top-l_18wu0_1g1na_894",
  "m-vertical-l": "awsui_m-vertical-l_18wu0_1g1na_895",
  "m-right-l": "awsui_m-right-l_18wu0_1g1na_899",
  "m-horizontal-l": "awsui_m-horizontal-l_18wu0_1g1na_900",
  "m-bottom-l": "awsui_m-bottom-l_18wu0_1g1na_904",
  "m-left-l": "awsui_m-left-l_18wu0_1g1na_909",
  "m-xl": "awsui_m-xl_18wu0_1g1na_914",
  "m-top-xl": "awsui_m-top-xl_18wu0_1g1na_918",
  "m-vertical-xl": "awsui_m-vertical-xl_18wu0_1g1na_919",
  "m-right-xl": "awsui_m-right-xl_18wu0_1g1na_923",
  "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_1g1na_924",
  "m-bottom-xl": "awsui_m-bottom-xl_18wu0_1g1na_928",
  "m-left-xl": "awsui_m-left-xl_18wu0_1g1na_933",
  "m-xxl": "awsui_m-xxl_18wu0_1g1na_938",
  "m-top-xxl": "awsui_m-top-xxl_18wu0_1g1na_942",
  "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_1g1na_943",
  "m-right-xxl": "awsui_m-right-xxl_18wu0_1g1na_947",
  "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_1g1na_948",
  "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_1g1na_952",
  "m-left-xxl": "awsui_m-left-xxl_18wu0_1g1na_957",
  "m-xxxl": "awsui_m-xxxl_18wu0_1g1na_962",
  "m-top-xxxl": "awsui_m-top-xxxl_18wu0_1g1na_966",
  "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_1g1na_967",
  "m-right-xxxl": "awsui_m-right-xxxl_18wu0_1g1na_971",
  "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_1g1na_972",
  "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_1g1na_976",
  "m-left-xxxl": "awsui_m-left-xxxl_18wu0_1g1na_981",
  "d-block": "awsui_d-block_18wu0_1g1na_986",
  "d-inline": "awsui_d-inline_18wu0_1g1na_989",
  "d-inline-block": "awsui_d-inline-block_18wu0_1g1na_992",
  "d-none": "awsui_d-none_18wu0_1g1na_995",
  "f-left": "awsui_f-left_18wu0_1g1na_999",
  "f-right": "awsui_f-right_18wu0_1g1na_1003"
};
  