
    import './styles.scoped.css';
    export default {
  "appbar": "awsui_appbar_hyvsj_lb3sr_93",
  "appbar-nav": "awsui_appbar-nav_hyvsj_lb3sr_117",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_lb3sr_121",
  "appbar-tools": "awsui_appbar-tools_hyvsj_lb3sr_125",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_lb3sr_166",
  "has-notifications-content": "awsui_has-notifications-content_hyvsj_lb3sr_173",
  "has-header": "awsui_has-header_hyvsj_lb3sr_179",
  "has-dynamic-overlap-height": "awsui_has-dynamic-overlap-height_hyvsj_lb3sr_179",
  "content-type-wizard": "awsui_content-type-wizard_hyvsj_lb3sr_180",
  "content-type-cards": "awsui_content-type-cards_hyvsj_lb3sr_183",
  "content-type-table": "awsui_content-type-table_hyvsj_lb3sr_184",
  "has-sticky-background": "awsui_has-sticky-background_hyvsj_lb3sr_187",
  "background": "awsui_background_hyvsj_lb3sr_221",
  "notifications-appbar-header": "awsui_notifications-appbar-header_hyvsj_lb3sr_224",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_lb3sr_237",
  "overlap": "awsui_overlap_hyvsj_lb3sr_243",
  "content": "awsui_content_hyvsj_lb3sr_180",
  "layout": "awsui_layout_hyvsj_lb3sr_335",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_lb3sr_418",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_lb3sr_433",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_lb3sr_460",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_lb3sr_463",
  "has-sticky-notifications": "awsui_has-sticky-notifications_hyvsj_lb3sr_467",
  "has-split-panel": "awsui_has-split-panel_hyvsj_lb3sr_481",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_lb3sr_481",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_lb3sr_493",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_lb3sr_496",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_lb3sr_511",
  "container": "awsui_container_hyvsj_lb3sr_519",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_lb3sr_545",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_lb3sr_550",
  "is-tools-open": "awsui_is-tools-open_hyvsj_lb3sr_553",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_lb3sr_553",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_lb3sr_553",
  "content-type-default": "awsui_content-type-default_hyvsj_lb3sr_573",
  "content-type-form": "awsui_content-type-form_hyvsj_lb3sr_573",
  "unfocusable": "awsui_unfocusable_hyvsj_lb3sr_614",
  "navigation-container": "awsui_navigation-container_hyvsj_lb3sr_624",
  "show-navigation": "awsui_show-navigation_hyvsj_lb3sr_659",
  "animating": "awsui_animating_hyvsj_lb3sr_681",
  "showButtons": "awsui_showButtons_hyvsj_lb3sr_1",
  "navigation": "awsui_navigation_hyvsj_lb3sr_624",
  "openNavigation": "awsui_openNavigation_hyvsj_lb3sr_1",
  "animated-content": "awsui_animated-content_hyvsj_lb3sr_742",
  "hide-navigation": "awsui_hide-navigation_hyvsj_lb3sr_751",
  "notifications": "awsui_notifications_hyvsj_lb3sr_224",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_lb3sr_818",
  "position-bottom": "awsui_position-bottom_hyvsj_lb3sr_864",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_lb3sr_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_lb3sr_893",
  "position-side": "awsui_position-side_hyvsj_lb3sr_921",
  "openSplitPanelSide": "awsui_openSplitPanelSide_hyvsj_lb3sr_1",
  "tools-container": "awsui_tools-container_hyvsj_lb3sr_958",
  "tools": "awsui_tools_hyvsj_lb3sr_958",
  "openTools": "awsui_openTools_hyvsj_lb3sr_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_lb3sr_1043",
  "hide-tools": "awsui_hide-tools_hyvsj_lb3sr_1053",
  "show-tools": "awsui_show-tools_hyvsj_lb3sr_1065",
  "has-tools-form": "awsui_has-tools-form_hyvsj_lb3sr_1043",
  "trigger": "awsui_trigger_hyvsj_lb3sr_1122",
  "selected": "awsui_selected_hyvsj_lb3sr_1161"
};
  