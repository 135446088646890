
    import './styles.scoped.css';
    export default {
  "dialog": "awsui_dialog_1d2i7_18k42_105",
  "modal-slide-up": "awsui_modal-slide-up_1d2i7_18k42_1",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1d2i7_18k42_1",
  "refresh": "awsui_refresh_1d2i7_18k42_128",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_1d2i7_18k42_1",
  "root": "awsui_root_1d2i7_18k42_161",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_1d2i7_18k42_1",
  "hidden": "awsui_hidden_1d2i7_18k42_226",
  "focus-lock": "awsui_focus-lock_1d2i7_18k42_230",
  "small": "awsui_small_1d2i7_18k42_248",
  "medium": "awsui_medium_1d2i7_18k42_251",
  "large": "awsui_large_1d2i7_18k42_254",
  "max": "awsui_max_1d2i7_18k42_257",
  "breakpoint-xs": "awsui_breakpoint-xs_1d2i7_18k42_257",
  "container": "awsui_container_1d2i7_18k42_262",
  "content": "awsui_content_1d2i7_18k42_281",
  "no-paddings": "awsui_no-paddings_1d2i7_18k42_284",
  "header": "awsui_header_1d2i7_18k42_288",
  "header--text": "awsui_header--text_1d2i7_18k42_295",
  "footer": "awsui_footer_1d2i7_18k42_299",
  "dismiss-control": "awsui_dismiss-control_1d2i7_18k42_309",
  "modal-open": "awsui_modal-open_1d2i7_18k42_313"
};
  