import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { Home } from './Home'
import { ResourceList } from './resource/ResourceList'
import { ResourceDetail } from './resource/ResourceDetail'
import { ResourceEdit } from './resource/ResourceEdit'
import { RoleList } from './role/RoleList'
import { RoleEdit } from './role/RoleEdit'
import { RoleDetail } from './role/RoleDetail'
import { TagList } from './tag/TagList'
import { TagEdit } from './tag/TagEdit'
import { TagDetail } from './tag/TagDetail'
import { TypeEdit } from './type/TypeEdit'
import { TypeList } from './type/TypeList'
import { UserList } from './user/UserList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user === null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/resource' element={<ResourceList />} />
          <Route path='/resource/:resourceId' element={<ResourceDetail />} />
          <Route path='/resource/create' element={<ResourceEdit />} />
          <Route path='/resource/:resourceId/edit' element={<ResourceEdit />} />

          <Route path='/tag' element={<TagList />} />
          <Route path='/tag/create' element={<TagEdit />} />
          <Route path='/tag/:tagId' element={<TagDetail />} />
          <Route path='/tag/:tagId/edit' element={<TagEdit />} />

          <Route path='/type' element={<TypeList />} />
          <Route path='/type/:entity' element={<TypeEdit />} />

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='*' element={<RadNotFound />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound />}
        {loadingCount === 0 && permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
