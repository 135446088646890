import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadIcon } from '../common/RadIcon'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { toBase64 } from '../common/utilities'

export function ResourceEdit () {
  const navigate = useNavigate()
  const fileInput = useRef(null)
  const { resourceId } = useParams()
  const { data: resource } = useGet(resourceId ? `/api/resource/${resourceId}` : null)
  const [formValues, setFormValues] = useState()
  const [userFilterText, setUserFilterText] = useState('')
  const { data: typeOptions } = useGet('/api/option/type?entity=resource')
  const { data: tags } = useGet('/api/tag')
  const sourceOptions = [
    { value: '0', label: 'File' },
    { value: '1', label: 'Link' },
    { value: '2', label: 'Text' }
  ]
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}`)

  useEffect(() => {
    const defaultFormValues = { sourceId: 0, isPrivate: false, files: [], shares: [], tags: [] }
    setFormValues(resource != null ? { ...resource, files: [] } : defaultFormValues)
  }, [resource])

  const create = usePost('/api/resource', formValues, (resp) => { navigate(`/resource/${resp.id}`) })
  const update = usePut(`/api/resource/${resourceId}`, formValues, (resp) => { navigate(`/resource/${resourceId}`) })

  async function saveChanges () {
    if (resourceId != null) { update() } else { create() }
  }

  async function cancel () {
    if (resourceId != null) {
      navigate(`/resource/${resourceId}`)
    } else {
      navigate('/resource')
    }
  }

  function chooseFile () {
    fileInput.current.click()
  }

  function removeFile () {
    setFormValues({ ...formValues, file: null, fileSize: null, fileData: null })
  }

  async function onFileChange (event) {
    const file = event.target.files[0]
    if (file != null) {
      const data = await toBase64(file)
      setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData: data })
    } else {
      removeFile()
    }
    event.target.value = ''
  }

  if (formValues != null && tags != null && typeOptions != null) {
    return (
      <RadAppLayout
        name={formValues.title}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Resource' : 'New Resource'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <input type='file' ref={fileInput} onChange={onFileChange} style={{ display: 'none' }} />
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Title *' field='title'>
                      <RadInput
                        placeholder='Enter title'
                        ariaRequired
                        value={formValues.title}
                        onChange={({ detail }) => setFormValues({ ...formValues, title: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Type *' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Source *' field='sourceId'>
                      <RadSelect
                        selectedOption={sourceOptions.find(x => x.value === formValues.sourceId.toString())}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, sourceId: parseInt(detail.selectedOption.value), text: null, file: null, fileSize: null })
                        }}
                        options={sourceOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    {
                      formValues.sourceId === 0 &&
                        <RadFormField field='file'>
                          {
                            formValues.file == null &&
                              <RadButton iconName='upload' onClick={chooseFile}>Choose file</RadButton>
                          }
                          {formValues.file != null &&
                            <RadBox textAlign='left'>
                              <RadBox float='left' padding={{ right: 's' }}>
                                <RadIcon name='status-positive' variant='success' />
                              </RadBox>
                              <RadBox float='left'>
                                {formValues.file.split('/').pop()}
                                <br />
                                {formValues.fileSize < (1024 * 1024) ? `${parseInt(formValues.fileSize / 1024)} KB` : `${parseInt(formValues.fileSize / 1024 / 1024)} MB`}
                              </RadBox>
                              <RadBox float='right'>
                                <RadButton iconName='delete-marker' onClick={removeFile}>Remove file</RadButton>
                              </RadBox>
                            </RadBox>}
                        </RadFormField>
                    }
                    {
                      formValues.sourceId === 1 &&
                        <RadFormField label='Link *' field='link'>
                          <RadInput
                            type='url'
                            inputMode='url'
                            placeholder='Enter link'
                            value={formValues.link}
                            onChange={({ detail }) => setFormValues({ ...formValues, link: detail.value, file: null, fileSize: null })}
                          />
                        </RadFormField>
                    }
                    {
                      formValues.sourceId === 2 &&
                        <RadFormField label='Text *' field='text'>
                          <RadTextarea
                            rows={18}
                            placeholder='Enter text'
                            value={formValues.text}
                            onChange={({ detail }) => setFormValues({ ...formValues, text: detail.value })}
                          />
                        </RadFormField>
                    }
                    <RadFormField label='Summary' field='summary'>
                      <RadTextarea
                        rows={6}
                        placeholder='Enter summary or leave blank to auto-generate.'
                        value={formValues.summary}
                        onChange={({ detail }) => setFormValues({ ...formValues, summary: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Publisher' field='publisher'>
                      <RadInput
                        placeholder='Enter publisher'
                        value={formValues.publisher}
                        onChange={({ detail }) => setFormValues({ ...formValues, publisher: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Publication Date' field='publicationDate'>
                      <RadDatePicker
                        onChange={({ detail }) => setFormValues({ ...formValues, publicationDate: detail.value })}
                        value={formValues.publicationDate ?? ''}
                        openCalendarAriaLabel={selectedDate =>
                          'Choose a date' +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : '')}
                      />
                    </RadFormField>
                    <RadFormField label='Author' field='author'>
                      <RadInput
                        placeholder='Enter author'
                        value={formValues.author}
                        onChange={({ detail }) => setFormValues({ ...formValues, author: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const tag = formValues.tags.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    tag.id = null
                                    tag.tag.id = id
                                    tag.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this resource.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Visibility</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadCheckbox
                      checked={formValues.isPrivate}
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, isPrivate: detail.checked })
                      }}
                    >
                      Private
                    </RadCheckbox>
                    {formValues.isPrivate &&
                      <RadAttributeEditor
                        onAddButtonClick={() => {
                          const shares = formValues.shares
                          shares.push({ uuid: uuidv4(), allowEdit: false, user: {} })
                          setFormValues({ ...formValues, shares })
                        }}
                        onRemoveButtonClick={({
                          detail: { itemIndex }
                        }) => {
                          const shares = [...formValues.shares]
                          shares.splice(itemIndex, 1)
                          setFormValues({ ...formValues, shares })
                        }}
                        items={formValues.shares}
                        addButtonText='Add new user'
                        definition={[
                          {
                            label: 'User *',
                            control: item => {
                              const filteredOptions = (userOptions ?? []).filter((x) => !formValues.shares.map((y) => y.userId?.toString()).includes(x.value))
                              const selectedOption = item.userId ? { value: item.userId.toString(), label: item.user.name } : null
                              return (
                                <RadFormField field={`shares.${item.id || item.uuid}.userId`}>
                                  <RadSelect
                                    selectedOption={selectedOption}
                                    onChange={({ detail }) => {
                                      const shares = formValues.shares
                                      const user = formValues.shares.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                      user.userId = parseInt(detail.selectedOption.value)
                                      user.user.name = detail.selectedOption.label
                                      setFormValues({ ...formValues, shares })
                                    }}
                                    options={filteredOptions}
                                    enteredTextLabel={value => value}
                                    selectedAriaLabel='Selected'
                                    placeholder='Choose a user'
                                    filteringType='manual'
                                    onLoadItems={({ detail }) => {
                                      setUserFilterText(detail.filteringText)
                                    }}
                                    empty={userFilterText ? 'No matches found' : null}
                                  />
                                </RadFormField>
                              )
                            }
                          },
                          {
                            label: 'Permission *',
                            control: item => {
                              const options = [
                                { value: false, label: 'View only' },
                                { value: true, label: 'View and edit' }
                              ]
                              return (
                                <RadFormField field={`shares.${item.id ?? item.uuid}.allowEdit`}>
                                  <RadSelect
                                    filteringType='auto'
                                    selectedOption={options.find(x => x.value === item.allowEdit)}
                                    onChange={({ detail }) => {
                                      const shares = formValues.shares
                                      const user = formValues.shares.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                      user.allowEdit = detail.selectedOption.value
                                      setFormValues({ ...formValues, shares })
                                    }}
                                    options={options}
                                    enteredTextLabel={value => value}
                                    selectedAriaLabel='Selected'
                                    placeholder='Choose a sector'
                                    empty='No matches found'
                                  />
                                </RadFormField>
                              )
                            }
                          }
                        ]}
                        removeButtonText='Remove'
                        empty='No users added to this resource.'
                      />}
                  </RadSpaceBetween>
                </RadContainer>

              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
