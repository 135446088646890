import React, { useEffect, useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppContext } from './../App'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { getDownloadUrl, getShortText } from '../common/utilities'

export function ResourceList ({ selected }) {
  const { user } = useContext(AppContext)
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tagIds') ?? '')
  const { data: resources, count } = useGet(`/api/resource?search=${searchText}&tagIds=${tagIds ?? ''}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const { data: tags } = useGet('/api/tag?limit=2000')

  useEffect(() => {
    const getIdToken = async () => {
      const token = await user.getIdToken()
      setToken(token)
    }
    getIdToken()
  }, [user])

  if (resources != null && tags != null) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/resource/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Resources
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/resource/${item.id}`}>{item.title}</RadLink>,
              sections: [
                {
                  id: 'source',
                  content: item => {
                    switch (item.sourceId) {
                      case 0:
                        return (
                          <RadSpaceBetween>
                            <RadLink external externalIconAriaLabel='Opens in a new tab' href={getDownloadUrl(item.id, item.file + `?token=${token}`)} target='_blank'>source</RadLink>
                            {item.fileSize < (1024 * 1024) ? `${parseInt(item.fileSize / 1024)} KB` : `${parseInt(item.fileSize / 1024 / 1024)} MB`}
                          </RadSpaceBetween>
                        )
                      case 1:
                        return <RadLink external externalIconAriaLabel='Opens in a new tab' href={item.link} target='_blank'>source</RadLink>
                      case 2:
                        return 'Text'
                      default:
                        return '-'
                    }
                  }
                },
                {
                  id: 'summary',
                  header: 'Summary',
                  content: item => getShortText(item.summary) ?? '-'
                },
                {
                  id: 'tags',
                  header: 'Tags',
                  content: item => {
                    if (item.tags.length > 0) {
                      return item.tags.map((x) => x.name).join(', ')
                    } else {
                      return '-'
                    }
                  }
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={resources}
            variant='full-page'
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, s: 8 } },
                  { colspan: { default: 12, s: 4 } }
                ]}
              >
                <RadInput2
                  initialValue={searchText}
                  placeholder='Search'
                  type='search'
                  onEnter={({ detail }) => {
                    setSearchText(detail.value)
                    if (detail.value) {
                      searchParams.set('search', detail.value)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadMultiselect
                  filteringType='auto'
                  selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('tagIds')
                    } else {
                      searchParams.set('tagIds', ids)
                    }
                    setTagIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={tagOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose tags'
                  keepOpen={false}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
