
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_k5dlb_1wffp_93",
  "top-navigation": "awsui_top-navigation_k5dlb_1wffp_111",
  "padding-box": "awsui_padding-box_k5dlb_1wffp_124",
  "medium": "awsui_medium_k5dlb_1wffp_134",
  "narrow": "awsui_narrow_k5dlb_1wffp_134",
  "virtual": "awsui_virtual_k5dlb_1wffp_145",
  "hidden": "awsui_hidden_k5dlb_1wffp_149",
  "\t": "awsui_\t_k5dlb_1wffp_1",
  "identity": "awsui_identity_k5dlb_1wffp_161",
  "identity-link": "awsui_identity-link_k5dlb_1wffp_164",
  "no-logo": "awsui_no-logo_k5dlb_1wffp_181",
  "logo": "awsui_logo_k5dlb_1wffp_185",
  "title": "awsui_title_k5dlb_1wffp_197",
  "inputs": "awsui_inputs_k5dlb_1wffp_212",
  "search": "awsui_search_k5dlb_1wffp_219",
  "search-expanded": "awsui_search-expanded_k5dlb_1wffp_223",
  "utilities": "awsui_utilities_k5dlb_1wffp_227",
  "utility-wrapper": "awsui_utility-wrapper_k5dlb_1wffp_238",
  "utility-type-button-link": "awsui_utility-type-button-link_k5dlb_1wffp_259",
  "utility-type-menu-dropdown": "awsui_utility-type-menu-dropdown_k5dlb_1wffp_263",
  "utility-type-button-primary-button": "awsui_utility-type-button-primary-button_k5dlb_1wffp_271",
  "utility-link-icon": "awsui_utility-link-icon_k5dlb_1wffp_279",
  "utility-button-external-icon": "awsui_utility-button-external-icon_k5dlb_1wffp_283",
  "offset-right-none": "awsui_offset-right-none_k5dlb_1wffp_287",
  "offset-right-l": "awsui_offset-right-l_k5dlb_1wffp_291",
  "offset-right-xxl": "awsui_offset-right-xxl_k5dlb_1wffp_295",
  "overflow-menu-drawer": "awsui_overflow-menu-drawer_k5dlb_1wffp_300",
  "overflow-menu": "awsui_overflow-menu_k5dlb_1wffp_300",
  "overflow-menu-header": "awsui_overflow-menu-header_k5dlb_1wffp_322",
  "overflow-menu-header-text": "awsui_overflow-menu-header-text_k5dlb_1wffp_329",
  "overflow-menu-header-text--secondary": "awsui_overflow-menu-header-text--secondary_k5dlb_1wffp_340",
  "overflow-menu-header-text--title": "awsui_overflow-menu-header-text--title_k5dlb_1wffp_346",
  "overflow-menu-back-button": "awsui_overflow-menu-back-button_k5dlb_1wffp_350",
  "overflow-menu-dismiss-button": "awsui_overflow-menu-dismiss-button_k5dlb_1wffp_354",
  "overflow-menu-control": "awsui_overflow-menu-control_k5dlb_1wffp_358",
  "overflow-menu-list-item-utility": "awsui_overflow-menu-list-item-utility_k5dlb_1wffp_375",
  "overflow-menu-control-link": "awsui_overflow-menu-control-link_k5dlb_1wffp_382",
  "overflow-menu-control-expandable-menu-trigger": "awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1wffp_385",
  "overflow-menu-list": "awsui_overflow-menu-list_k5dlb_1wffp_375",
  "overflow-menu-list-submenu": "awsui_overflow-menu-list-submenu_k5dlb_1wffp_416",
  "overflow-menu-list-item": "awsui_overflow-menu-list-item_k5dlb_1wffp_375",
  "overflow-menu-list-item-icon": "awsui_overflow-menu-list-item-icon_k5dlb_1wffp_424",
  "overflow-menu-list-item-text": "awsui_overflow-menu-list-item-text_k5dlb_1wffp_427",
  "overflow-menu-list-item-submenu": "awsui_overflow-menu-list-item-submenu_k5dlb_1wffp_436",
  "overflow-menu-list-item-dropdown-menu": "awsui_overflow-menu-list-item-dropdown-menu_k5dlb_1wffp_441",
  "overflow-menu-list-item-expandable": "awsui_overflow-menu-list-item-expandable_k5dlb_1wffp_444",
  "expanded": "awsui_expanded_k5dlb_1wffp_452"
};
  