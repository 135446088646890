import React from 'react'
import DatePicker from '@cloudscape-design/components-themed/date-picker'

// https://cloudscape.design/components/date-picker
export function RadDatePicker ({ ...rest }) {
  return (
    <DatePicker
      {...rest}
      placeholder='YYYY/MM/DD'
      nextMonthAriaLabel='Next month'
      previousMonthAriaLabel='Previous month'
      todayAriaLabel='Today'
    />
  )
}
