
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_1ii6b_97",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_1ii6b_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_1ii6b_124",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_1ii6b_1",
  "error": "awsui_error_14mhv_1ii6b_97",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_1ii6b_1",
  "root": "awsui_root_14mhv_1ii6b_168",
  "label": "awsui_label_14mhv_1ii6b_182",
  "info": "awsui_info_14mhv_1ii6b_195",
  "description": "awsui_description_14mhv_1ii6b_200",
  "constraint": "awsui_constraint_14mhv_1ii6b_201",
  "hints": "awsui_hints_14mhv_1ii6b_208",
  "constraint-has-error": "awsui_constraint-has-error_14mhv_1ii6b_209",
  "secondary-control": "awsui_secondary-control_14mhv_1ii6b_213",
  "controls": "awsui_controls_14mhv_1ii6b_217",
  "label-hidden": "awsui_label-hidden_14mhv_1ii6b_217",
  "control": "awsui_control_14mhv_1ii6b_217",
  "error__message": "awsui_error__message_14mhv_1ii6b_236",
  "visually-hidden": "awsui_visually-hidden_14mhv_1ii6b_240"
};
  