
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_1qsyl_93",
  "header": "awsui_header_l0dv0_1qsyl_109",
  "header-link": "awsui_header-link_l0dv0_1qsyl_120",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_1qsyl_130",
  "header-link-text": "awsui_header-link-text_l0dv0_1qsyl_130",
  "header-logo": "awsui_header-logo_l0dv0_1qsyl_134",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_1qsyl_140",
  "list-container": "awsui_list-container_l0dv0_1qsyl_145",
  "list": "awsui_list_l0dv0_1qsyl_145",
  "list-variant-root": "awsui_list-variant-root_l0dv0_1qsyl_155",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_1qsyl_161",
  "list-item": "awsui_list-item_l0dv0_1qsyl_165",
  "section": "awsui_section_l0dv0_1qsyl_171",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_1qsyl_172",
  "section--no-ident": "awsui_section--no-ident_l0dv0_1qsyl_175",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_1qsyl_176",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_1qsyl_189",
  "section-group": "awsui_section-group_l0dv0_1qsyl_194",
  "section-group-title": "awsui_section-group-title_l0dv0_1qsyl_204",
  "link": "awsui_link_l0dv0_1qsyl_208",
  "link-active": "awsui_link-active_l0dv0_1qsyl_217",
  "info": "awsui_info_l0dv0_1qsyl_251",
  "external-icon": "awsui_external-icon_l0dv0_1qsyl_255",
  "divider": "awsui_divider_l0dv0_1qsyl_259",
  "divider-default": "awsui_divider-default_l0dv0_1qsyl_264",
  "divider-header": "awsui_divider-header_l0dv0_1qsyl_268"
};
  