
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_1kosq_71jun_93",
  "breadcrumb": "awsui_breadcrumb_1kosq_71jun_97",
  "icon": "awsui_icon_1kosq_71jun_100",
  "anchor": "awsui_anchor_1kosq_71jun_104",
  "last": "awsui_last_1kosq_71jun_147",
  "compressed": "awsui_compressed_1kosq_71jun_157",
  "text": "awsui_text_1kosq_71jun_161",
  "virtual-item": "awsui_virtual-item_1kosq_71jun_168",
  "item-popover": "awsui_item-popover_1kosq_71jun_175"
};
  