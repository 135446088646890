
    import './styles.scoped.css';
    export default {
  "button": "awsui_button_m5h9f_18pqz_93",
  "expanded": "awsui_expanded_m5h9f_18pqz_127",
  "offset-right-none": "awsui_offset-right-none_m5h9f_18pqz_138",
  "offset-right-l": "awsui_offset-right-l_m5h9f_18pqz_141",
  "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_18pqz_144",
  "text": "awsui_text_m5h9f_18pqz_166",
  "icon": "awsui_icon_m5h9f_18pqz_170"
};
  