
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_1ccxv_93",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_1ccxv_1",
  "root": "awsui_root_mx3cw_1ccxv_119",
  "hidden": "awsui_hidden_mx3cw_1ccxv_135",
  "body": "awsui_body_mx3cw_1ccxv_160",
  "header": "awsui_header_mx3cw_1ccxv_166",
  "action": "awsui_action_mx3cw_1ccxv_170",
  "action-button": "awsui_action-button_mx3cw_1ccxv_175",
  "text": "awsui_text_mx3cw_1ccxv_179",
  "icon": "awsui_icon_mx3cw_1ccxv_183",
  "message": "awsui_message_mx3cw_1ccxv_186",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_1ccxv_194",
  "content": "awsui_content_mx3cw_1ccxv_208",
  "dismiss": "awsui_dismiss_mx3cw_1ccxv_212",
  "dismiss-button": "awsui_dismiss-button_mx3cw_1ccxv_218",
  "type-error": "awsui_type-error_mx3cw_1ccxv_226",
  "type-warning": "awsui_type-warning_mx3cw_1ccxv_234",
  "type-success": "awsui_type-success_mx3cw_1ccxv_242",
  "type-info": "awsui_type-info_mx3cw_1ccxv_250"
};
  