
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_i6ym0_97",
  "button": "awsui_button_vjswe_i6ym0_101",
  "variant-normal": "awsui_variant-normal_vjswe_i6ym0_126",
  "is-activated": "awsui_is-activated_vjswe_i6ym0_139",
  "variant-icon": "awsui_variant-icon_vjswe_i6ym0_166",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_i6ym0_166",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_i6ym0_166",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_i6ym0_184",
  "disabled": "awsui_disabled_vjswe_i6ym0_202",
  "variant-primary": "awsui_variant-primary_vjswe_i6ym0_210",
  "variant-link": "awsui_variant-link_vjswe_i6ym0_294",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_i6ym0_714",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_i6ym0_801",
  "button-no-text": "awsui_button-no-text_vjswe_i6ym0_885",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_i6ym0_889",
  "icon-left": "awsui_icon-left_vjswe_i6ym0_905",
  "icon-right": "awsui_icon-right_vjswe_i6ym0_910",
  "icon": "awsui_icon_vjswe_i6ym0_905"
};
  