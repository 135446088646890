
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_lm6vo_12tk9_97",
  "layout-wrapper": "awsui_layout-wrapper_lm6vo_12tk9_98",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_12tk9_105",
  "layout": "awsui_layout_lm6vo_12tk9_98",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_12tk9_116",
  "layout-main": "awsui_layout-main_lm6vo_12tk9_120",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_12tk9_126",
  "unfocusable": "awsui_unfocusable_lm6vo_12tk9_131",
  "navigation-panel": "awsui_navigation-panel_lm6vo_12tk9_135",
  "drawer": "awsui_drawer_lm6vo_12tk9_139",
  "drawer-mobile": "awsui_drawer-mobile_lm6vo_12tk9_145",
  "drawer-closed": "awsui_drawer-closed_lm6vo_12tk9_148",
  "drawer-content": "awsui_drawer-content_lm6vo_12tk9_156",
  "toggle": "awsui_toggle_lm6vo_12tk9_178",
  "visual-refresh-toggle": "awsui_visual-refresh-toggle_lm6vo_12tk9_183",
  "visual-refresh-toggle-type-tools": "awsui_visual-refresh-toggle-type-tools_lm6vo_12tk9_187",
  "visual-refresh-toggle-type-navigation": "awsui_visual-refresh-toggle-type-navigation_lm6vo_12tk9_190",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_12tk9_200",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_12tk9_205",
  "content-wrapper": "awsui_content-wrapper_lm6vo_12tk9_209",
  "content-overlapped": "awsui_content-overlapped_lm6vo_12tk9_213",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_12tk9_217",
  "panel-wrapper-outer": "awsui_panel-wrapper-outer_lm6vo_12tk9_221",
  "mobile": "awsui_mobile_lm6vo_12tk9_227",
  "open": "awsui_open_lm6vo_12tk9_233",
  "panel-wrapper-inner": "awsui_panel-wrapper-inner_lm6vo_12tk9_239"
};
  