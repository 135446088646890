
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_wih1l_8pv4k_97",
  "tools": "awsui_tools_wih1l_8pv4k_108",
  "tools-filtering": "awsui_tools-filtering_wih1l_8pv4k_114",
  "tools-align-right": "awsui_tools-align-right_wih1l_8pv4k_128",
  "tools-pagination": "awsui_tools-pagination_wih1l_8pv4k_132",
  "tools-preferences": "awsui_tools-preferences_wih1l_8pv4k_132",
  "tools-small": "awsui_tools-small_wih1l_8pv4k_138",
  "table": "awsui_table_wih1l_8pv4k_144",
  "table-layout-fixed": "awsui_table-layout-fixed_wih1l_8pv4k_150",
  "wrapper": "awsui_wrapper_wih1l_8pv4k_154",
  "variant-stacked": "awsui_variant-stacked_wih1l_8pv4k_162",
  "variant-container": "awsui_variant-container_wih1l_8pv4k_162",
  "variant-embedded": "awsui_variant-embedded_wih1l_8pv4k_166",
  "has-header": "awsui_has-header_wih1l_8pv4k_169",
  "has-footer": "awsui_has-footer_wih1l_8pv4k_172",
  "cell-merged": "awsui_cell-merged_wih1l_8pv4k_186",
  "cell-merged-content": "awsui_cell-merged-content_wih1l_8pv4k_197",
  "empty": "awsui_empty_wih1l_8pv4k_212",
  "loading": "awsui_loading_wih1l_8pv4k_216",
  "selection-control": "awsui_selection-control_wih1l_8pv4k_225",
  "selection-control-header": "awsui_selection-control-header_wih1l_8pv4k_232",
  "sticky-scrollbar": "awsui_sticky-scrollbar_wih1l_8pv4k_237",
  "sticky-scrollbar-content": "awsui_sticky-scrollbar-content_wih1l_8pv4k_247",
  "sticky-scrollbar-visible": "awsui_sticky-scrollbar-visible_wih1l_8pv4k_250",
  "dark-header": "awsui_dark-header_wih1l_8pv4k_258",
  "header-secondary": "awsui_header-secondary_wih1l_8pv4k_263",
  "table-has-header": "awsui_table-has-header_wih1l_8pv4k_279",
  "header-controls": "awsui_header-controls_wih1l_8pv4k_283",
  "footer-wrapper": "awsui_footer-wrapper_wih1l_8pv4k_297",
  "footer": "awsui_footer_wih1l_8pv4k_297",
  "thead-active": "awsui_thead-active_wih1l_8pv4k_306",
  "row": "awsui_row_wih1l_8pv4k_307",
  "row-selected": "awsui_row-selected_wih1l_8pv4k_308"
};
  