
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1mabk_tdicb_93",
  "radio": "awsui_radio_1mabk_tdicb_107",
  "radio--has-description": "awsui_radio--has-description_1mabk_tdicb_115",
  "radio-control": "awsui_radio-control_1mabk_tdicb_119",
  "outline": "awsui_outline_1mabk_tdicb_127",
  "styled-circle-border": "awsui_styled-circle-border_1mabk_tdicb_146",
  "styled-circle-disabled": "awsui_styled-circle-disabled_1mabk_tdicb_150",
  "styled-circle-fill": "awsui_styled-circle-fill_1mabk_tdicb_155",
  "styled-circle-checked": "awsui_styled-circle-checked_1mabk_tdicb_161"
};
  