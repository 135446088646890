
    import './styles.scoped.css';
    export default {
  "content-enter": "awsui_content-enter_gwq0h_udygu_97",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_gwq0h_udygu_1",
  "trigger-expanded": "awsui_trigger-expanded_gwq0h_udygu_119",
  "icon": "awsui_icon_gwq0h_udygu_133",
  "root": "awsui_root_gwq0h_udygu_151",
  "expanded": "awsui_expanded_gwq0h_udygu_171",
  "icon-container": "awsui_icon-container_gwq0h_udygu_175",
  "trigger": "awsui_trigger_gwq0h_udygu_119",
  "trigger-default": "awsui_trigger-default_gwq0h_udygu_190",
  "trigger-footer": "awsui_trigger-footer_gwq0h_udygu_193",
  "trigger-navigation": "awsui_trigger-navigation_gwq0h_udygu_199",
  "trigger-container": "awsui_trigger-container_gwq0h_udygu_213",
  "header": "awsui_header_gwq0h_udygu_223",
  "header-container": "awsui_header-container_gwq0h_udygu_226",
  "header-container-button": "awsui_header-container-button_gwq0h_udygu_242",
  "header-navigation": "awsui_header-navigation_gwq0h_udygu_250",
  "content": "awsui_content_gwq0h_udygu_97",
  "content-default": "awsui_content-default_gwq0h_udygu_286",
  "content-footer": "awsui_content-footer_gwq0h_udygu_289",
  "content-expanded": "awsui_content-expanded_gwq0h_udygu_292",
  "focusable": "awsui_focusable_gwq0h_udygu_296"
};
  