
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18582_1f6cg_93",
  "child": "awsui_child_18582_1f6cg_97",
  "horizontal": "awsui_horizontal_18582_1f6cg_108",
  "horizontal-xxxs": "awsui_horizontal-xxxs_18582_1f6cg_112",
  "horizontal-xxs": "awsui_horizontal-xxs_18582_1f6cg_116",
  "horizontal-xs": "awsui_horizontal-xs_18582_1f6cg_120",
  "horizontal-s": "awsui_horizontal-s_18582_1f6cg_124",
  "horizontal-m": "awsui_horizontal-m_18582_1f6cg_128",
  "horizontal-l": "awsui_horizontal-l_18582_1f6cg_132",
  "horizontal-xl": "awsui_horizontal-xl_18582_1f6cg_136",
  "horizontal-xxl": "awsui_horizontal-xxl_18582_1f6cg_140",
  "child-horizontal-xxxs": "awsui_child-horizontal-xxxs_18582_1f6cg_145",
  "child-horizontal-xxs": "awsui_child-horizontal-xxs_18582_1f6cg_151",
  "child-horizontal-xs": "awsui_child-horizontal-xs_18582_1f6cg_157",
  "child-horizontal-s": "awsui_child-horizontal-s_18582_1f6cg_163",
  "child-horizontal-m": "awsui_child-horizontal-m_18582_1f6cg_169",
  "child-horizontal-l": "awsui_child-horizontal-l_18582_1f6cg_175",
  "child-horizontal-xl": "awsui_child-horizontal-xl_18582_1f6cg_181",
  "child-horizontal-xxl": "awsui_child-horizontal-xxl_18582_1f6cg_187",
  "vertical": "awsui_vertical_18582_1f6cg_196",
  "child-vertical-xxxs": "awsui_child-vertical-xxxs_18582_1f6cg_200",
  "child-vertical-xxs": "awsui_child-vertical-xxs_18582_1f6cg_204",
  "child-vertical-xs": "awsui_child-vertical-xs_18582_1f6cg_208",
  "child-vertical-s": "awsui_child-vertical-s_18582_1f6cg_212",
  "child-vertical-m": "awsui_child-vertical-m_18582_1f6cg_216",
  "child-vertical-l": "awsui_child-vertical-l_18582_1f6cg_220",
  "child-vertical-xl": "awsui_child-vertical-xl_18582_1f6cg_224",
  "child-vertical-xxl": "awsui_child-vertical-xxl_18582_1f6cg_228"
};
  