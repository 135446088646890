
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_14yj0_1u9p5_93",
  "no-gutters": "awsui_no-gutters_14yj0_1u9p5_109",
  "grid-column": "awsui_grid-column_14yj0_1u9p5_113",
  "colspan-1": "awsui_colspan-1_14yj0_1u9p5_122",
  "push-1": "awsui_push-1_14yj0_1u9p5_126",
  "pull-1": "awsui_pull-1_14yj0_1u9p5_129",
  "colspan-2": "awsui_colspan-2_14yj0_1u9p5_132",
  "push-2": "awsui_push-2_14yj0_1u9p5_136",
  "pull-2": "awsui_pull-2_14yj0_1u9p5_139",
  "colspan-3": "awsui_colspan-3_14yj0_1u9p5_142",
  "push-3": "awsui_push-3_14yj0_1u9p5_146",
  "pull-3": "awsui_pull-3_14yj0_1u9p5_149",
  "colspan-4": "awsui_colspan-4_14yj0_1u9p5_152",
  "push-4": "awsui_push-4_14yj0_1u9p5_156",
  "pull-4": "awsui_pull-4_14yj0_1u9p5_159",
  "colspan-5": "awsui_colspan-5_14yj0_1u9p5_162",
  "push-5": "awsui_push-5_14yj0_1u9p5_166",
  "pull-5": "awsui_pull-5_14yj0_1u9p5_169",
  "colspan-6": "awsui_colspan-6_14yj0_1u9p5_172",
  "push-6": "awsui_push-6_14yj0_1u9p5_176",
  "pull-6": "awsui_pull-6_14yj0_1u9p5_179",
  "colspan-7": "awsui_colspan-7_14yj0_1u9p5_182",
  "push-7": "awsui_push-7_14yj0_1u9p5_186",
  "pull-7": "awsui_pull-7_14yj0_1u9p5_189",
  "colspan-8": "awsui_colspan-8_14yj0_1u9p5_192",
  "push-8": "awsui_push-8_14yj0_1u9p5_196",
  "pull-8": "awsui_pull-8_14yj0_1u9p5_199",
  "colspan-9": "awsui_colspan-9_14yj0_1u9p5_202",
  "push-9": "awsui_push-9_14yj0_1u9p5_206",
  "pull-9": "awsui_pull-9_14yj0_1u9p5_209",
  "colspan-10": "awsui_colspan-10_14yj0_1u9p5_212",
  "push-10": "awsui_push-10_14yj0_1u9p5_216",
  "pull-10": "awsui_pull-10_14yj0_1u9p5_219",
  "colspan-11": "awsui_colspan-11_14yj0_1u9p5_222",
  "push-11": "awsui_push-11_14yj0_1u9p5_226",
  "pull-11": "awsui_pull-11_14yj0_1u9p5_229",
  "colspan-12": "awsui_colspan-12_14yj0_1u9p5_232",
  "push-12": "awsui_push-12_14yj0_1u9p5_236",
  "pull-12": "awsui_pull-12_14yj0_1u9p5_239",
  "push-0": "awsui_push-0_14yj0_1u9p5_242",
  "pull-0": "awsui_pull-0_14yj0_1u9p5_245",
  "offset-1": "awsui_offset-1_14yj0_1u9p5_248",
  "offset-2": "awsui_offset-2_14yj0_1u9p5_251",
  "offset-3": "awsui_offset-3_14yj0_1u9p5_254",
  "offset-4": "awsui_offset-4_14yj0_1u9p5_257",
  "offset-5": "awsui_offset-5_14yj0_1u9p5_260",
  "offset-6": "awsui_offset-6_14yj0_1u9p5_263",
  "offset-7": "awsui_offset-7_14yj0_1u9p5_266",
  "offset-8": "awsui_offset-8_14yj0_1u9p5_269",
  "offset-9": "awsui_offset-9_14yj0_1u9p5_272",
  "offset-10": "awsui_offset-10_14yj0_1u9p5_275",
  "offset-11": "awsui_offset-11_14yj0_1u9p5_278",
  "restore-pointer-events": "awsui_restore-pointer-events_14yj0_1u9p5_282"
};
  