import React, { useEffect, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './../App'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { formatDate, getDownloadUrl } from '../common/utilities'

export function ResourceDetail () {
  const { user } = useContext(AppContext)
  const [token, setToken] = useState()
  const navigate = useNavigate()
  const { resourceId } = useParams()
  const { data: resource } = useGet(`/api/resource/${resourceId}`)
  const confirmDelete = useConfirm('Delete resource?', 'Delete resource permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/resource/${resourceId}`, () => { navigate('/resource') })

  useEffect(() => {
    const getIdToken = async () => {
      const token = await user.getIdToken()
      setToken(token)
    }
    getIdToken()
  }, [user])

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (resource != null) {
    return (
      <RadAppLayout
        name={resource.title}
        notifications={resource.summaryStatus != null ? [{ type: 'info', content: resource.summaryStatus }] : []}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={resource.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {resource.title}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            {/* Summary */}
            <RadContainer
              header={
                <RadHeader variant='h3'>
                  The Gist
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <div>{resource.summary ?? '-'}</div>
              </RadSpaceBetween>
            </RadContainer>

            {/* Content */}
            {
              resource.sourceId === 0 && resource.file != null &&
                <RadContainer
                  header={
                    <RadHeader variant='h3'>
                      Content
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='s'>
                    <object data={getDownloadUrl(resource.id, resource.file + `?token=${token}`)} type='application/pdf' width='100%' height='500px'>
                      <div>Unable to display file. <a href={getDownloadUrl(resource.id, resource.file + `?token=${token}`)}>Download</a> instead.</div>
                    </object>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, s: 8 } },
                        { colspan: { default: 12, s: 4 } }
                      ]}
                    >
                      {resource.file.split('/').pop()}
                      {resource.fileSize < (1024 * 1024) ? `${parseInt(resource.fileSize / 1024)} KB` : `${parseInt(resource.fileSize / 1024 / 1024)} MB`}
                    </RadGrid>
                  </RadSpaceBetween>
                </RadContainer>
            }
            {
              resource.sourceId === 1 &&
                <RadContainer
                  header={
                    <RadHeader variant='h3'>
                      Link
                    </RadHeader>
                  }
                >
                  <RadLink external externalIconAriaLabel='Opens in a new tab' href={resource.link} target='_blank'>{resource.link}</RadLink>
                </RadContainer>
            }
            {
              resource.sourceId === 2 &&
                <RadContainer
                  header={
                    <RadHeader variant='h3'>
                      Content
                    </RadHeader>
                  }
                >
                  <RadLineBreakRenderer text={resource.text} />
                </RadContainer>
            }

            {/* Meta info */}
            <RadContainer
              header={
                <RadHeader variant='h3'>
                  Meta
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>Type</RadBox>
                  <div>{resource.type.name}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>Publisher</RadBox>
                  <div>{resource.publisher ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>Publication Date </RadBox>
                  <div>{resource.publicationDate != null ? formatDate(resource.publicationDate) : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>Author</RadBox>
                  <div>{resource.author ?? '-'}</div>
                </div>
              </RadColumnLayout>
              <div>
                <RadBox variant='awsui-key-label'>Tags</RadBox>
                <div>{resource.tags.length > 0 ? resource.tags.map((x) => x.tag.name).join(', ') : '-'}</div>
              </div>
              <div>
                <RadBox variant='awsui-key-label'>
                  Visibility<RadBox color='text-body-secondary' display='inline'>{resource.isPrivate === true ? ' (Private)' : ''}</RadBox>
                </RadBox>
                {resource.shares.map((x) => <div key={x.id}><RadLink href={`/user/${x.user.id}`}>{x.user.name}</RadLink> - {x.allowEdit === false ? 'View only' : 'View and edit'}</div>)}
                {resource.isPrivate === false ? 'Public' : ''}
              </div>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
