import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadBox } from '../common/RadBox'

export function TagList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: tags, count } = useGet(`/api/tag?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)

  if (tags != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/tag/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Tags
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/tag/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'entities',
                  content: item =>
                    <RadSpaceBetween>
                      <RadLink href={`/resource?tagIds=${item.id}`}>Resources ({item.resourceCount})</RadLink>
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={tags}
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
            variant='full-page'
            filter={
              <RadTextFilter
                filteringPlaceholder='Search'
                filteringAriaLabel='Search tags'
                filteringText={filteringText}
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText)
                  setCurrentPageIndex(1)
                  searchParams.set('page', 1)
                  if (detail.filteringText != null) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
          />
        }
      />
    )
  }
}
