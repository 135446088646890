import React from 'react'

export function RadLineBreakRenderer ({ text }) {
  return (
    <>
      {
        text.includes('\n')
          ? text.split('\n')
            .map((x, idx) => { return <React.Fragment key={idx}>{x}<br /></React.Fragment> })
          : text
      }
    </>
  )
}
