
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_zmuag_93",
  "root-no-actions": "awsui_root-no-actions_2qdw9_zmuag_110",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_zmuag_110",
  "root-variant-h1-refresh": "awsui_root-variant-h1-refresh_2qdw9_zmuag_113",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_zmuag_116",
  "root-has-description": "awsui_root-has-description_2qdw9_zmuag_116",
  "root-variant-h2-refresh": "awsui_root-variant-h2-refresh_2qdw9_zmuag_119",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_zmuag_122",
  "root-variant-h3-refresh": "awsui_root-variant-h3-refresh_2qdw9_zmuag_125",
  "root-no-wrap": "awsui_root-no-wrap_2qdw9_zmuag_128",
  "main": "awsui_main_2qdw9_zmuag_132",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_zmuag_140",
  "main-variant-h1-refresh": "awsui_main-variant-h1-refresh_2qdw9_zmuag_143",
  "main-variant-h2": "awsui_main-variant-h2_2qdw9_zmuag_149",
  "main-variant-h3": "awsui_main-variant-h3_2qdw9_zmuag_149",
  "main-variant-h2-refresh": "awsui_main-variant-h2-refresh_2qdw9_zmuag_155",
  "actions": "awsui_actions_2qdw9_zmuag_162",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_zmuag_166",
  "actions-variant-h1-refresh": "awsui_actions-variant-h1-refresh_2qdw9_zmuag_169",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_zmuag_172",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_zmuag_172",
  "actions-variant-h2-refresh": "awsui_actions-variant-h2-refresh_2qdw9_zmuag_178",
  "actions-variant-h3-refresh": "awsui_actions-variant-h3-refresh_2qdw9_zmuag_181",
  "title": "awsui_title_2qdw9_zmuag_185",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_zmuag_191",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_zmuag_194",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_zmuag_194",
  "title-variant-h2-refresh": "awsui_title-variant-h2-refresh_2qdw9_zmuag_200",
  "info": "awsui_info_2qdw9_zmuag_207",
  "description": "awsui_description_2qdw9_zmuag_211",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_zmuag_216",
  "description-variant-h1-refresh": "awsui_description-variant-h1-refresh_2qdw9_zmuag_221",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_zmuag_224",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_zmuag_229",
  "description-variant-h3-refresh": "awsui_description-variant-h3-refresh_2qdw9_zmuag_235",
  "heading": "awsui_heading_2qdw9_zmuag_239",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_zmuag_248",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_zmuag_253",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_zmuag_258",
  "heading-text": "awsui_heading-text_2qdw9_zmuag_264",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_zmuag_267",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_zmuag_275",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_zmuag_283",
  "counter": "awsui_counter_2qdw9_zmuag_292"
};
  