
    import './styles.scoped.css';
    export default {
  "dropdown-content-wrapper": "awsui_dropdown-content-wrapper_qwoo0_fu6up_93",
  "awsui-motion-fade-in-dropdown": "awsui_awsui-motion-fade-in-dropdown_qwoo0_fu6up_1",
  "refresh": "awsui_refresh_qwoo0_fu6up_114",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_qwoo0_fu6up_1",
  "root": "awsui_root_qwoo0_fu6up_143",
  "interior": "awsui_interior_qwoo0_fu6up_157",
  "dropdown": "awsui_dropdown_qwoo0_fu6up_93",
  "use-portal": "awsui_use-portal_qwoo0_fu6up_168",
  "dropdown-drop-up": "awsui_dropdown-drop-up_qwoo0_fu6up_213",
  "with-limited-width": "awsui_with-limited-width_qwoo0_fu6up_217",
  "dropdown-drop-left": "awsui_dropdown-drop-left_qwoo0_fu6up_220",
  "dropdown-drop-right": "awsui_dropdown-drop-right_qwoo0_fu6up_223",
  "occupy-entire-width": "awsui_occupy-entire-width_qwoo0_fu6up_226",
  "hide-upper-border": "awsui_hide-upper-border_qwoo0_fu6up_229",
  "open": "awsui_open_qwoo0_fu6up_236",
  "nowrap": "awsui_nowrap_qwoo0_fu6up_245",
  "is-empty": "awsui_is-empty_qwoo0_fu6up_249",
  "dropdown-content": "awsui_dropdown-content_qwoo0_fu6up_93",
  "ie11-wrapper": "awsui_ie11-wrapper_qwoo0_fu6up_259",
  "stretch-trigger-height": "awsui_stretch-trigger-height_qwoo0_fu6up_263"
};
  