
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_1wepg_w9dfp_93",
  "description": "awsui_description_1wepg_w9dfp_94",
  "label": "awsui_label_1wepg_w9dfp_95",
  "outline": "awsui_outline_1wepg_w9dfp_99",
  "show-outline": "awsui_show-outline_1wepg_w9dfp_102",
  "native-input": "awsui_native-input_1wepg_w9dfp_106",
  "wrapper": "awsui_wrapper_1wepg_w9dfp_110",
  "label-wrapper": "awsui_label-wrapper_1wepg_w9dfp_117",
  "empty-content": "awsui_empty-content_1wepg_w9dfp_129",
  "description-bottom-padding": "awsui_description-bottom-padding_1wepg_w9dfp_139",
  "label-disabled": "awsui_label-disabled_1wepg_w9dfp_147",
  "description-disabled": "awsui_description-disabled_1wepg_w9dfp_148",
  "control": "awsui_control_1wepg_w9dfp_153"
};
  