
    import './styles.scoped.css';
    export default {
  "header": "awsui_header_16mm3_1ve9d_93",
  "disabled": "awsui_disabled_16mm3_1ve9d_108",
  "expandable-header": "awsui_expandable-header_16mm3_1ve9d_112",
  "rolled-down": "awsui_rolled-down_16mm3_1ve9d_123",
  "highlighted": "awsui_highlighted_16mm3_1ve9d_126",
  "is-focused": "awsui_is-focused_16mm3_1ve9d_139",
  "variant-navigation": "awsui_variant-navigation_16mm3_1ve9d_143",
  "category": "awsui_category_16mm3_1ve9d_155",
  "expandable": "awsui_expandable_16mm3_1ve9d_112",
  "expand-icon": "awsui_expand-icon_16mm3_1ve9d_176",
  "expand-icon-up": "awsui_expand-icon-up_16mm3_1ve9d_183",
  "expand-icon-right": "awsui_expand-icon-right_16mm3_1ve9d_186",
  "items-list-container": "awsui_items-list-container_16mm3_1ve9d_200"
};
  