
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_l23lj_97",
  "fit-height": "awsui_fit-height_14iqq_l23lj_111",
  "variant-default": "awsui_variant-default_14iqq_l23lj_116",
  "variant-stacked": "awsui_variant-stacked_14iqq_l23lj_116",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_l23lj_169",
  "header": "awsui_header_14iqq_l23lj_176",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_l23lj_181",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_l23lj_185",
  "header-stuck": "awsui_header-stuck_14iqq_l23lj_191",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_l23lj_197",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_l23lj_200",
  "with-paddings": "awsui_with-paddings_14iqq_l23lj_206",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_l23lj_209",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_l23lj_259",
  "dark-header": "awsui_dark-header_14iqq_l23lj_277",
  "content": "awsui_content_14iqq_l23lj_282",
  "with-content-paddings": "awsui_with-content-paddings_14iqq_l23lj_286",
  "with-footer": "awsui_with-footer_14iqq_l23lj_293",
  "footer": "awsui_footer_14iqq_l23lj_300",
  "with-divider": "awsui_with-divider_14iqq_l23lj_303"
};
  