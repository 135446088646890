
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_1ykar_dp693_93",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1ykar_dp693_1",
  "root": "awsui_root_1ykar_dp693_124",
  "calendar-inner": "awsui_calendar-inner_1ykar_dp693_143",
  "calendar-header": "awsui_calendar-header_1ykar_dp693_146",
  "calendar-header-month": "awsui_calendar-header-month_1ykar_dp693_151",
  "calendar-next-month-btn": "awsui_calendar-next-month-btn_1ykar_dp693_158",
  "calendar-prev-month-btn": "awsui_calendar-prev-month-btn_1ykar_dp693_161",
  "calendar-grid": "awsui_calendar-grid_1ykar_dp693_164",
  "calendar-grid-cell": "awsui_calendar-grid-cell_1ykar_dp693_168",
  "calendar-day-header": "awsui_calendar-day-header_1ykar_dp693_174",
  "calendar-day": "awsui_calendar-day_1ykar_dp693_174",
  "calendar-day-enabled": "awsui_calendar-day-enabled_1ykar_dp693_191",
  "calendar-day-current-month": "awsui_calendar-day-current-month_1ykar_dp693_198",
  "calendar-day-selected": "awsui_calendar-day-selected_1ykar_dp693_205",
  "calendar-day-today": "awsui_calendar-day-today_1ykar_dp693_208",
  "day-inner": "awsui_day-inner_1ykar_dp693_223",
  "calendar-week": "awsui_calendar-week_1ykar_dp693_288"
};
  